import React from "react"
import "./index.scss"

const ChallengeCardItem = ({ card_info }) => {
  return (
    <div
      id="ChallengeCardItem"
      className="ChallengeCardItem px-lg-3"
      data-testid="ChallengeCardItem"
    >
      <div className="row">
        <div className="challenge-card-title col-8">
          <h3>{card_info.title}</h3>
        </div>
        <div className="challenge-card-logo col-4">
          <img
            src={card_info.logo}
            alt="IBM Maximo: Pick a challenge"
            title="IBM Maximo: Pick a challenge"
          />
        </div>
      </div>
      <div className="challenge-card-description">
        <p
          dangerouslySetInnerHTML={{
            __html: card_info.description,
          }}
        ></p>
      </div>
    </div>
  )
}

export default ChallengeCardItem
