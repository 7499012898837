import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import EntrepriseSupportCard from "./entreprise-support-card"
import "./index.scss"
const EntrepriseSupport = ({ _mock }) => {
  let result
  try {
    const data = useStaticQuery(graphql`
      {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/(entreprise-support)/" } }
        ) {
          nodes {
            frontmatter {
              title1
              title2
              description
              supportLogo1
              supportLogo2
              supportTitle1
              supportTitle2
              supportDescription1
              supportDescription2
            }
          }
        }
      }
    `)
    result = data.allMdx.nodes[0].frontmatter
  } catch {
    result = _mock
  }
  const card_one = {
    logo: result.supportLogo1,
    title: result.supportTitle1,
    description: result.supportDescription1,
  }
  const card_two = {
    logo: result.supportLogo2,
    title: result.supportTitle2,
    description: result.supportDescription2,
  }
  return (
    <div
      id="EntrepriseSupport"
      className="EntrepriseSupport container-lg"
      data-testid="EntrepriseSupport"
    >
      <div className="support-title">
        <h2>
          {result.title1} <br />
          {result.title2}
        </h2>
      </div>
      <div className="support-description">
        <p dangerouslySetInnerHTML={{ __html: result.description }}></p>
      </div>
      <div className="row">
        <div className="col-sm-6 support-card">
          <EntrepriseSupportCard card_info={card_one} />
        </div>
        <div className="col-sm-6 support-card">
          <EntrepriseSupportCard card_info={card_two} />
        </div>
      </div>
    </div>
  )
}

export default EntrepriseSupport
