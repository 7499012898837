import React from "react"
import EntreprisePresentation from "./entreprise-presentation"
import EntrepriseCards from "./entreprise-challenges"
import EntrepriseConcierge from "./entreprise-concierge"
import EntrepriseSupport from "./entreprise-support"
import EntrepriseForm from "./entreprise-form-wrapper"
import Seo from "../seo"
const EntrepriseMain = () => {
  return (
    <div
      id="EntrepriseMain"
      className="EntrepriseMain py-5"
      data-testid="EntrepriseMain"
    >
      <Seo
        title="Professional Enterprise Services"
        description="MAXapps enables Maximo professionals to build performant, secure, flexible, and versatile business processes."
        titleog="MAXapps Enterprise: Professional Services for MAXapps"
        descriptionog="A fully managed service for your IBM Maximo mobile integration. experience with your own personal concierge service for your Maximo mobile."
        image="https://maxapps-maximo.com/og/Enterprise.jpg"
        url="https://maxapps-maximo.com/entreprise/"
      />
      <EntreprisePresentation />
      <EntrepriseCards />
      <EntrepriseConcierge />
      <EntrepriseSupport />
      <EntrepriseForm />
    </div>
  )
}

export default EntrepriseMain
