import React from "react"
import EntrepriseMain from "../components/entreprise"
import Layout from "../components/layout/t1/layout"

const Entreprise = () => {
  return (
    <Layout>
      <EntrepriseMain />
    </Layout>
  )
}

export default Entreprise
