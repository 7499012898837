import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import YellowButton from "../../home/buttons/yellow-button"

import "./index.scss"
const EntrepriseFormWrapper = ({ _mock }) => {
  let result
  try {
    const data = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(entreprise-form)/" } }) {
          nodes {
            frontmatter {
              topTitle
              title
              description
            }
          }
        }
      }
    `)
    result = data.allMdx.nodes[0].frontmatter
  } catch {
    result = _mock
  }
  return (
    <div
      id="EntrepriseFormWrapper"
      className="EntrepriseFormWrapper container"
      data-testid="EntrepriseFormWrapper"
    >
      <div className="form-toptitle">
        <h2>{result.topTitle}</h2>
      </div>
      <div className="entreprise-form-title">
        <p>{result.title}</p>
      </div>
      <div className="entreprise-form-description">
        <p>{result.description}</p>
      </div>
      <div className="button">
        <YellowButton data={"Contact Us"} size={"180px"} to="/contact/" />
      </div>
    </div>
  )
}

export default EntrepriseFormWrapper
