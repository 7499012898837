import React from "react"
import "./index.scss"

const EntrepriseSupportCard = ({ card_info }) => {
  return (
    <div
      id="EntrepriseSupportCard"
      className="EntrepriseSupportCard"
      data-testid="EntrepriseSupportCard"
    >
      <div className="support-card-logo">
        <img
          src={card_info.logo}
          alt="suport-card-logo"
          title="suport-card-logo"
        />
      </div>
      <div className="support-card-title">
        <h3>{card_info.title}</h3>
      </div>
      <div className="support-card-desc">
        <p dangerouslySetInnerHTML={{ __html: card_info.description }}></p>
      </div>
    </div>
  )
}

export default EntrepriseSupportCard
