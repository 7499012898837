import React from "react"
import { Link } from "gatsby"
import "./index.scss"
const YellowButton = ({ data, children, size, to = "/get-started/" }) => {
  return (
    <div id="YellowButton" className="YellowButton" data-testid="YellowButton">
      <Link to={to} className="yellow-btn-text">
        <button
          className="yellow-btn"
          style={{ width: `${size}` }}
          data-testid="yellowBtn"
        >
          {data}
          {children}
        </button>
      </Link>
    </div>
  )
}

export default YellowButton
