import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"

const EntrepriseConcierge = ({ _mock }) => {
  let result
  try {
    const data = useStaticQuery(graphql`
      {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/(entreprise-concierge)/" } }
        ) {
          nodes {
            frontmatter {
              title
              description
            }
          }
        }
      }
    `)
    result = data.allMdx.nodes[0].frontmatter
  } catch {
    result = _mock
  }
  return (
    <div
      id="EntrepriseConcierge"
      className="EntrepriseConcierge container-lg"
      data-testid="EntrepriseConcierge"
    >
      <div className="row">
        <div className="col-lg-5">
          <div className="concierge-title">
            <h2 className="title">{result.title}</h2>
          </div>
          <div className="concierge-description">
            <p dangerouslySetInnerHTML={{ __html: result.description }}></p>
          </div>
        </div>
        <div className="col-lg-7 d-flex p-lg-0 py-5 justify-content-center concierge-image">
          <img
            src="/concierge_.png"
            alt="build the best ibm Maximo mobile experience"
            title="build the best ibm Maximo mobile experience"
          />
        </div>
      </div>
    </div>
  )
}

export default EntrepriseConcierge
