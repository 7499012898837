import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
const EntreprisePresentation = ({ _mock }) => {
  let result
  try {
    const data = useStaticQuery(graphql`
      {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/(entreprise-presentation)/" } }
        ) {
          nodes {
            frontmatter {
              logo
              title1
              title2
              title3
              description1
              description2
            }
          }
        }
      }
    `)
    result = data.allMdx.nodes[0].frontmatter
  } catch {
    result = _mock
  }
  return (
    <div
      id="EntreprisePresentation"
      className="EntreprisePresentation"
      data-testid="EntreprisePresentation"
    >
      <div className="container">
        <h1 className="title">
          {result.title1} <br />
          <span className="subTitleMain">
            {result.title2} {result.title3}
          </span>
        </h1>
        <p
          dangerouslySetInnerHTML={{
            __html: result.description1,
          }}
        ></p>
      </div>
    </div>
  )
}

export default EntreprisePresentation
