import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
import ChallengeCardItem from "./entreprise-card-item"

const EntrepriseCards = ({ _mock }) => {
  let result
  try {
    const data = useStaticQuery(graphql`
      {
        allMdx(
          filter: { fileAbsolutePath: { regex: "/(entreprise-challenges)/" } }
        ) {
          nodes {
            frontmatter {
              title
              challengeTitle1
              challengeTitle2
              challengeTitle3
              challengeLogo1
              challengeLogo2
              challengeLogo3
              challengeDescription3
              challengeDescription2
              challengeDescription1
            }
          }
        }
      }
    `)
    result = data.allMdx.nodes[0].frontmatter
  } catch {
    result = _mock
  }
  const card_one = {
    logo: result.challengeLogo1,
    title: result.challengeTitle1,
    description: result.challengeDescription1,
  }
  const card_two = {
    logo: result.challengeLogo2,
    title: result.challengeTitle2,
    description: result.challengeDescription2,
  }
  const card_three = {
    logo: result.challengeLogo3,
    title: result.challengeTitle3,
    description: result.challengeDescription3,
  }
  return (
    <div
      id="EntrepriseCards"
      className="EntrepriseCards"
      data-testid="EntrepriseCards"
    >
      <div className="entreprise-cards-title">
        <h2 className="title">{result.title}</h2>
      </div>
      <div className="container-lg">
        <div className="row px-2 challenge-cards">
          <div className="col-sm-6 col-md-4">
            <ChallengeCardItem card_info={card_one} />
          </div>
          <div className="col-sm-6 col-md-4">
            <ChallengeCardItem card_info={card_two} />
          </div>
          <div className="col-sm-6 col-md-4">
            <ChallengeCardItem card_info={card_three} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EntrepriseCards
